<template>
  <!-- Footer con información de Omniwallet y política privacidad -->

  <div class="footer-login">
    <router-link to="/politica-privacidad">
      <p class="q-ma-sm cursor-pointer">{{ $t("Login.privacy-politics") }}</p>
    </router-link>

    <p class="q-ma-sm cursor-pointer" @click="dialog = true">
      {{ $t("Login.faq") }}
    </p>

    <router-link class="q-ma-sm cursor-pointer" to="forgot-password" style="font-size: 12px">
      {{ $t("Login.forgotten-password") }}
    </router-link>

    <p class="q-ma-sm cursor-pointer" @click="pagina = true"></p>
    <p class="q-ma-sm cursor-pointer" @click="confirmPage = true">
      Powered by OmniWallet
    </p>

    <q-dialog persistent v-model="confirmPage">
      <q-card :style="{ 'font-family': settings.typography }">
        <q-card-section class="text-center">
          <div class="text-body1 q-pb-md">
            {{ $t("Login.web-omniwallet") }}
          </div>
        </q-card-section>
        <q-card-actions align="center">
          <a
            class="btn-home"
            href="https://www.omniwallet.net/"
            target="_blank"
          >
            <q-btn
              target="_blank"
              class="btn-home"
              :label="$t('Login.web-access')"
              :style="{ backgroundColor: settings.color_primary }"
            />
          </a>
        </q-card-actions>
        <q-card-actions align="center">
          <q-btn
            class="btn-home"
            :label="$t('Login.go-back-app')"
            :style="{ backgroundColor: settings.color_accent }"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <QAquestions v-model="dialog" />

  
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import settings from "@/mixins/settings";

export default {
  mixins: [settings],
  components: {
    QAquestions: defineAsyncComponent(() =>
      import("../../shared/components/QAquestions.vue")
    ),

    
  },
  data() {
    return {
      dialog: false,
      confirmPage: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer-login {
  margin-top: 2rem;
  p {
    font-size: 12px;
  }
}

.q-card {
  border-radius: 25px 25px 25px 0px;
}

.btn-home {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  text-transform: capitalize;
  width: 100%;
  color: white;
}
</style>
